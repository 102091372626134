export const TaskBoardStatus = {
	COMPLETED: 'completed',
	INCOMPLETE: 'incomplete',
	ASSIGNED: 'assigned',
	NEW: 'new'
};

export const TaskActionType = {
	EDIT: 'edit',
	CREATE: 'create',
	COPY: 'copy',
	VIEW: 'view'
};

export const ThirdPartyList = ['UPS', 'FEDEX', 'DHL', 'USPS', 'INTERNAL', 'OTHER'];

export const ReoccurringTypes = {
	F: 'F',
	W: 'W',
	M: 'M',
	MC: 'MC',
	MS: 'MS',
	Y: 'Y'
};

export const ReoccurringSettings = {
	SINGLE: 'Single',
	REOCCURRING: 'Reoccurring',

	STANDARD: 'standard',
	CUSTOM: 'custom',

	NOEND: 'noend',
	COUNT: 'count',
	DATE: 'date'
};

export const GRIDVIEWS = {
	NESTED: 'nested',
	TREE: 'tree',
	LIST: 'list',
	DAILY_PLANNER: 'dailyPlanner',
	NESTED_MASTER: 'nestedMaster',
	NESTED_CHILD: 'nestedChild',
	ATTACHMENT: 'attachment',
	ROUTE: 'route'
};

export const TASKVIEWS = {
	GRID: 'grid',
	MAP: 'map'
};

export const MODULE_PATH = {
	[GRIDVIEWS.LIST]: '/main/task-board/task-view',
	[GRIDVIEWS.NESTED]: '/main/task-board/reoccurring-view',
	[GRIDVIEWS.DAILY_PLANNER]: '/main/task-board/daily-planner'
};

export const MODULE_NAME = {
	[GRIDVIEWS.LIST]: 'task-view',
	[GRIDVIEWS.NESTED]: 'reoccurring-view',
	[GRIDVIEWS.DAILY_PLANNER]: 'daily-planner'
};

export const ORDER_METHODS = {
	DRAG: 'drag',
	NUMBER: 'number'
};

export const MONTH_NAMES = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December'
];

export const TIME_DISPLAY_OPTIONS = {
	TWENTY_FOUR_HOURS: 'twentyFourHours',
	TWELVE_HOURS: 'twelveHours'
};

export const DEFAULT_GRID_NULL_TEXT = '--';

export const BlankGridFilterParamText = '(Blank)';

export const FIELD_WORK_TASK_TYPE = 'Field Work Order';
export const COLLECT_SAMPLES_TASK_TYPE = 'Collect Samples';

export const TASK_FORM_TYPES = {
	FIELD_WORK_FORM: 'fieldWork',
	COLLECT_SAMPLE: 'collectSample'
};

export const DRAWER_TABS = {
	FIELD_WORK_INFO: 'fieldWorkInfo',
	ATTACHMENTS: 'attachments'
};


export const gridFieldNameToDbNameMap = {
	dueDateStr : 'dueDate',
	completedDateStr: 'completedDate',
	createdOnStr: 'createdOn',
	taskTypeDisplay: 'taskType',
	assignedName: 'assignedUsers',
	reoccurringTask: 'isRecurringTask',
	reoccurringTypeFullText: 'recurringType',
	isReoccurringActiveText: 'isActive',
	'busEntity.shortName': 'clientName',
	'sampleLocation.name': 'sampleLocName',
	'location.locName': 'locName',
};

export const defaultSkipCols = ['checkbox'];

export const DATE_FILTER_OPTIONS = {
	EQUALS: 'Equals',
	GREATER_THAN: 'Greater Than',
	LESS_THAN: 'Less Than',
	NOT_EQUALS: 'Not Equals',
	IN_RANGE: 'In Range'
};


export const FREQUENCY_TAGS = {
	YEARLY: 'annual',
	MONTHLY: 'monthly',
	WEEKLY: 'weekly',
	SEMI_ANNUAL: 'semi-annual',
	BI_MONTHLY: 'bi-monthly',
	QUARTERLY: 'quarterly',
	BI_WEEKLY: 'bi-weekly'
}

export const FILTER_VALUE_BACKEND_MAP = {
	Unassigned: 'Blanks',
	'Third Party': 'Thirdparty',
	'(Blank)': 'Blanks',
};

export const MODULES = {
	TASK_VIEW: 'task-view',
	RECC_VIEW: 'reoccurring-view',
	DAILY_PLANNER: 'daily-planner',
	SAMPLE_LOCATION: 'sample-location'
}

export const fieldDataCaptureFields = {
	totalChlorideResidual : 'Chlorine Residual, Total',
	// chlorideResidual : 'Chlorine Residual, Total',
	freeChlorideResidual : 'Chlorine Residual, Free',
	ampFreeChlorideResidual : 'Chlorine Residual, Amperometric Free',
	dissolvedOxygen : 'Dissolved Oxygen',
	flowRate : 'Flow Rate',
	flow : 'Flow',
	// flowRateUnit : 'Flow Rate Unit',
	pH : 'pH',
	temperature : 'Temperature',
	cyanide : 'Cyanide',
	dissolvedFiltering : 'Dissolved Filtering',
	fieldFiltration : 'Field Filtration',
	conductivity : 'Conductivity',
	hexCrFieldPreservation : 'Hex-Cr Field Preservation'
};